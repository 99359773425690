<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">Form Transaksi</h3>
        </div>
        <div class="panel-body">
          <vue-good-table
            mode="remote"
            @on-search="onSearch"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :search-options="{
              enabled: true,
              trigger: 'keyup',
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: false,
              perPageDropdownEnabled: false,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <button
                    class="btn btn-sm btn-block btn-primary"
                    v-on:click="fetchCostumers(props.row.customer_id)"
                  >
                    Pilih
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>

          <div v-if="detail.customer_name" class="mt-2">
            <div class="row">
              <div class="col-md-4">
                <div class="bg-yellow-transparent-1 rounded p-5">
                  <h6>Riwayat Transaksi</h6>
                  <div
                    v-for="history in historyPayment"
                    :key="history.transaction_id"
                    class="d-flex align-items-center m-b-15"
                  >
                    <div class="text-truncate">
                      <div>{{ history.additional_details }}</div>
                      <div class="text-grey">
                        {{ indonesianFormat(history.transaction_date) }}
                      </div>
                    </div>
                    <div class="ml-auto text-center">
                      <div class="f-s-13">
                        <span
                          data-animation="number"
                          :data-value="history.payment_amount"
                          >{{ currencyFormat(history.payment_amount) }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="bg-aqua-transparent-2 rounded p-5">
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 col-form-label push-right"
                      >Tahun
                    </label>
                    <div class="col-md-4">
                      <select
                        v-model="form.transaction_year"
                        v-on:change="resetMonth()"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.transaction_year,
                        }"
                      >
                        <option :value="currentYear - 1">
                          {{ currentYear - 1 }}
                        </option>
                        <option :value="currentYear">
                          {{ currentYear }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 col-form-label push-right"
                      >Periode Bayar
                    </label>
                    <div class="col-md-9">
                      <div class="input-group">
                        <select
                          v-model="period.first_month"
                          class="form-control col-md-5"
                          v-on:change="setFirstMonth($event)"
                          :class="{
                            'is-invalid': formValidate.additional_details,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="1">Januari</option>
                          <option value="2">Februari</option>
                          <option value="3">Maret</option>
                          <option value="4">April</option>
                          <option value="5">Mei</option>
                          <option value="6">Juni</option>
                          <option value="7">Juli</option>
                          <option value="8">Agustus</option>
                          <option value="9">September</option>
                          <option value="10">Oktober</option>
                          <option value="11">November</option>
                          <option value="12">Desember</option>
                        </select>
                        <span class="input-group-append">
                          <span
                            class="input-group-text"
                            style="height: calc(1.5em + 0.875rem + 2px)"
                          >
                            -
                          </span>
                        </span>
                        <select
                          v-model="period.last_month"
                          class="form-control col-md-5"
                          v-on:change="setLastMonth($event)"
                          :class="{
                            'is-invalid': formValidate.additional_details,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="1">Januari</option>
                          <option value="2">Februari</option>
                          <option value="3">Maret</option>
                          <option value="4">April</option>
                          <option value="5">Mei</option>
                          <option value="6">Juni</option>
                          <option value="7">Juli</option>
                          <option value="8">Agustus</option>
                          <option value="9">September</option>
                          <option value="10">Oktober</option>
                          <option value="11">November</option>
                          <option value="12">Desember</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="customer_name"
                      >Jumlah Bayar
                    </label>
                    <div class="col-md-8 align-middle">
                      <h5 class="mt-2">
                        Rp. {{ this.currencyFormat(form.payment_amount) }}
                      </h5>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="customer_name"
                      >Keterangan
                    </label>
                    <div class="col-md-8">
                      <h6 class="mt-2">{{ form.additional_details }}</h6>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="customer_name"
                      >Pembayaran
                    </label>
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-md-6">
                          <select
                            v-model="form.payment_method"
                            class="form-control m-b-5"
                            :class="{
                              'is-invalid': formValidate.payment_method,
                            }"
                          >
                            <option value="Tunai">Tunai</option>
                            <option value="Transfer">Transfer</option>
                          </select>
                        </div>
                        <div
                          class="col-md-12"
                          v-if="
                            form.payment_method == 'Transfer' &&
                            form.checking_account != ''
                          "
                        >
                          <table
                            class="table table-bordered bg-green-transparent-1"
                          >
                            <tr>
                              <td class="align-middle">Kode Transaksi</td>
                              <td>{{ checking_account.transaction_code }}</td>
                            </tr>
                            <tr>
                              <td>Date</td>
                              <td>{{ checking_account.posting_date }}</td>
                            </tr>
                            <tr>
                              <td>Remark</td>
                              <td>{{ checking_account.remark }}</td>
                            </tr>
                            <tr>
                              <td>Cost</td>
                              <td>
                                Rp.
                                {{ currencyFormat(checking_account.credit) }}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div
                          class="col-md-12"
                          v-if="form.payment_method == 'Transfer'"
                        >
                          <button @click="showModal" class="btn btn-primary">
                            Cocokan Bukti Transfer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 col-form-label push-right"
                      >Penagih
                    </label>
                    <div class="col-md-4">
                      <select
                        v-model="form.collector_name"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.collector_name,
                        }"
                      >
                        <option
                          v-for="satgas in collectorList"
                          :key="satgas.id_users"
                          :value="satgas.full_name"
                        >
                          {{ satgas.full_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer">
          <a href="javascript:window.history.go(-1);" class="btn btn-default"
            >Kembali</a
          >
          <button
            type="button"
            class="btn btn-teal pull-right"
            @click="postData()"
            :disabled="this.loading"
          >
            <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
            Submit
          </button>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Cocokan Bukti Transfer</h5>
      </template>
      <div>
        <div class="form-group">
          <input
            type="text"
            v-model="searchRemark"
            class="form-control"
            placeholder="Pencarian"
            @input="handleSearchRemark()"
          />
        </div>
        <table class="table table-bordered">
          <tbody v-for="remark in dataRemark" :key="remark.checking_account_id">
            <tr>
              <td colspan="2" class="align-middle">
                Kode Transaksi : <strong>{{ remark.transaction_code }}</strong>
              </td>
              <td class="text-center">
                <button
                  v-on:click="
                    setRemark(
                      remark.checking_account_id,
                      remark.transaction_code,
                      remark.posting_date,
                      remark.remark,
                      remark.credit
                    )
                  "
                  class="btn btn-sm btn-primary btn-block"
                >
                  Pilih
                </button>
              </td>
            </tr>
            <tr>
              <td class="text-center">Date</td>
              <td class="text-center">Remark</td>
              <td class="text-center">Cost</td>
            </tr>
            <tr>
              <td>{{ remark.posting_date }}</td>
              <td>{{ remark.remark }}</td>
              <td class="text-right">
                Rp. {{ currencyFormat(remark.credit) }}
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer>
        <button
          @click="closeModal"
          class="btn btn-block btn-info"
          variant="primary"
        >
          Tutup
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";
import moment from "moment";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "NPWRD",
          field: "npwrd",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama",
          field: "customer_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Alamat",
          field: "customer_address",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Kelurahan",
          field: "village_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Jenis Kegiatan",
          field: "category_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Satuan",
          field: "cost",
          sortable: false,
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass: "text-center text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      detail: {
        customer_id: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        cost: "",
        created_by: "",
        created_at: "",
      },
      form: {
        transaction_year: moment().format("YYYY"),
        transaction_date: moment().format("YYYY-MM-DD"),
        customer: "",
        payment_amount: "",
        payment_method: "",
        additional_details: "",
        checking_account: "",
        collector_name: "",
      },
      period: {
        first_month: "",
        last_month: "",
      },
      tableInfo: "Silahkan Masukan NPWRD/Nama Pelanggan",
      isLoading: false,
      loading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      formValidate: [],
      monthArray: [],
      historyPayment: [],
      currentYear: moment().format("YYYY"),
      modalVisible: false,
      conditionMet: false,
      searchRemark: "",
      dataRemark: [],
      checking_account: {
        transaction_code: "",
        posting_date: "",
        remark: "",
        credit: "",
      },
      collectorList: [],
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchCollector();
  },
  methods: {
    postData() {
      Swal.fire({
        title: "Submit Transaksi ?",
        text: "Harap Memastikan Data Sudah Sesuai",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Submit Transaksi",
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          const formData = new FormData();
          for (let key in this.form) {
            formData.append(key, this.form[key]);
          }
          formData.append("first_month", this.period.first_month);
          formData.append("last_month", this.period.last_month);
          axios
            .post("/v1/transactions/create/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                token: this.userToken,
              },
            })
            .then((response) => {
              if (response.data.validasi == false) {
                this.loading = false;
                this.formValidate = response.data.message;
              } else {
                this.loading = false;
                Swal.fire({
                  title: response.data.title,
                  text: response.data.message,
                  icon: response.data.icon,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
                setTimeout(() => {
                  Swal.close();
                  if (response.data.status) {
                    this.$router.go(-1);
                  }
                }, 700);
              }
            })
            .catch((error) => {
              console.log(error);
              this.error = error.message;
            });
        }
      });
    },
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        if (this.searchTerm) {
          this.tableInfo = "Mengambil data";
          this.fetchData();
        } else {
          this.tableInfo = "Silahkan Masukan NPWRD/Nama Pelanggan";
          this.rows = [];
          for (let key in this.detail) {
            this.detail[key] = "";
          }
        }
      }, 500);
    },

    fetchData() {
      axios
        .get("/v1/customers/search", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchCostumers(params) {
      axios
        .get("/v1/customers/detail/" + params)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.form.customer = response.data.data.customer_id;
          this.historyPayment = response.data.history;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchCollector() {
      axios
        .get("/v1/masters/collector/")
        .then((response) => {
          this.collectorList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/customers/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    resetMonth() {
      this.period.first_month = "";
      this.period.last_month = "";
      this.form.payment_amount = "";
      this.form.additional_details = "";
    },
    setFirstMonth(event) {
      // var currentDate = new Date();
      // var currentYear = currentDate.getFullYear();
      let currentYear = this.form.transaction_year;

      this.period.first_month = event.target.value;
      this.monthArray.push(parseInt(this.period.first_month));

      var monthNames = {
        1: "Januari",
        2: "Februari",
        3: "Maret",
        4: "April",
        5: "Mei",
        6: "Juni",
        7: "Juli",
        8: "Agustus",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Desember",
      };
      let first_month = parseInt(this.period.first_month);
      let last_month = parseInt(this.period.last_month);

      if (!last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      if (first_month > last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.period.last_month = "";
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      if (first_month < last_month) {
        this.form.additional_details =
          monthNames[first_month] +
          " s/d " +
          monthNames[last_month] +
          " " +
          currentYear;

        this.monthArray = [];
        for (var i = first_month; i <= last_month; i++) {
          this.monthArray.push(i);
        }
      }

      if (first_month == last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.period.last_month = "";
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      this.form.payment_amount = this.monthArray.length * this.detail.cost;
    },
    setLastMonth(event) {
      // var currentDate = new Date();
      // var currentYear = currentDate.getFullYear();
      let currentYear = this.form.transaction_year;
      this.period.last_month = event.target.value;

      let first_month = parseInt(this.period.first_month);
      let last_month = parseInt(this.period.last_month);

      var monthNames = {
        1: "Januari",
        2: "Februari",
        3: "Maret",
        4: "April",
        5: "Mei",
        6: "Juni",
        7: "Juli",
        8: "Agustus",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Desember",
      };

      if (!last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      if (first_month > last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.period.last_month = "";
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      if (first_month < last_month) {
        this.form.additional_details =
          monthNames[first_month] +
          " s/d " +
          monthNames[last_month] +
          " " +
          currentYear;
        this.monthArray = [];
        for (var i = first_month; i <= last_month; i++) {
          this.monthArray.push(i);
        }
      }

      if (first_month == last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.period.last_month = "";
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      this.form.payment_amount = this.monthArray.length * this.detail.cost;
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    handleSearchRemark() {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        if (this.searchRemark) {
          axios
            .get("/v1/checking_account/search", {
              headers: {
                token: this.userToken,
              },
              params: {
                search: this.searchRemark,
              },
            })
            .then((response) => {
              this.dataRemark = response.data.data;
            })
            .catch((error) => {
              console.log(error);
              this.error = error.message;
            });
        } else {
          this.dataRemark = [];
        }
      }, 500);
    },
    setRemark(id, transaction_code, posting_date, remark, credit) {
      this.form.checking_account = id;
      this.checking_account.transaction_code = transaction_code;
      this.checking_account.posting_date = posting_date;
      this.checking_account.remark = remark;
      this.checking_account.credit = credit;
      this.modalVisible = false;
      this.conditionMet = true;
    },
  },
};
</script>